<template>
  <input type="file" ref="input" @change="filechange" />
  <div ref="container" id="container"></div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { renderAsync } from "docx-preview";

export default defineComponent({
  name: "APP",
  setup() {
    let container = ref(null);

    function filechange(event) {
      renderAsync(
        event.target.files[0],
        document.getElementById("container"),
        null,
        {
          className: "docx", // 默认和文档样式类的类名/前缀
          inWrapper: true, // 启用围绕文档内容渲染包装器
          ignoreWidth: true, // 禁止页面渲染宽度
          ignoreHeight: true, // 禁止页面渲染高度
          ignoreFonts: true, // 禁止字体渲染
          breakPages: true, // 在分页符上启用分页
          ignoreLastRenderedPageBreak: true, //禁用lastRenderedPageBreak元素的分页
          experimental: false, //启用实验性功能（制表符停止计算）
          trimXmlDeclaration: true, //如果为真，xml声明将在解析之前从xml文档中删除
          debug: false, // 启用额外的日志记录
        }
      );
    }

    return {
      container,
      filechange,
    };
  },
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
